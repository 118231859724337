<template>
  <page-layout>
    <!-- ↓↓↓头部↓↓↓ -->
    <div slot="headerContent">
      <h1 class="title">角色分配</h1>
    </div>
    <div slot="extra">
      <div class="more-info">
        <a-button style="margin-left: 16px;" icon="plus" type="primary" @click="handleAdd">分配 </a-button>
      </div>
    </div>
    <!-- ↓↓↓表格↓↓↓ -->
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="">
            <a-input
              style="width: 300px;"
              v-model="searchParams.q"
              placeholder="请输入搜索内容（部门、职务、用户）"
              @pressEnter="search"
            />
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" :loading="tableLoading" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :scroll="{ x: 1000 }"
      >
        <span slot="serial" slot-scope="text, record, index">{{
          searchParams.number * searchParams.size + index + 1
        }}</span>
        <div slot="role" slot-scope="roles, record">
          <template v-for="one in roles">
            <a-tag
              :color="one.roleType === 'func' ? 'blue' : 'orange'"
              :key="record.id + '_' + one.id"
              closable
              @close="closeRole(one, record)"
              class="m-b-4 m-t-4"
              >{{ one.name }}</a-tag
            >
          </template>
        </div>
        <template slot="operation" slot-scope="text, record">
          <a-button type="danger" shape="circle" icon="delete" size="small" @click="handleDelete(record.id)"></a-button>
        </template>
      </a-table>
    </a-card>
    <a-modal
      title="添加权限分配"
      v-model="addModal"
      :maskClosable="false"
      okText="添加"
      cancelText="取消"
      width="500px"
      :confirmLoading="confirmButtonStatus"
      @ok="handleAddOk"
    >
      <auth-form
        type="add"
        ref="addRef"
        @addSuccess="addSuccess"
        @submitStart="handleConfirmStart"
        @addError="addError"
      ></auth-form>
    </a-modal>
  </page-layout>
</template>

<script>
import SERVICE_URLS from '../../../api/service.url'
import PageLayout from '../../../components/page/PageLayout'
import AuthForm from './AuthForm'
import entityCRUD from '../../../views/common/mixins/entityCRUD'

export default {
  name: 'AuthList',
  components: { PageLayout, AuthForm },
  mixins: [entityCRUD],
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.auth,
      searchParams: {
        number: 0,
        size: 10,
        direction: 'DESC',
        order: 'dateCreated',
        q: '',
      },
      initColumns: [
        {
          title: '#',
          align: 'center',
          scopedSlots: { customRender: 'serial' },
          width: 70,
        },
        {
          title: '名称',
          dataIndex: 'name',
          align: 'left',
          key: 'name',
          width: 300,
        },
        {
          title: '类型',
          dataIndex: 'type',
          align: 'center',
          key: 'type',
          width: 180,
        },
        {
          title: '分配的角色',
          dataIndex: 'role',
          align: 'left',
          key: 'role',
          scopedSlots: { customRender: 'role' },
        },
        {
          title: '创建时间',
          dataIndex: 'dateCreated',
          align: 'center',
          key: 'dateCreated',
          width: 130,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    }
  },
  methods: {
    handleAdd() {
      this.addModal = true
      this.$nextTick(() => {
        this.$refs.addRef.loadData()
      })
    },
    closeRole(role, value) {
      this.$http(this, {
        url: SERVICE_URLS.auth.update,
        params: {
          id: value.id,
          rId: role.id,
        },
        success: (data) => {
          if (value.role.length === 1) {
            this.search()
          }
          const index = value.role.findIndex((x) => x.id === role.id)
          if (index !== -1) {
            value.role.splice(index, 1)
          }
        },
      })

      console.log(role)
    },
  },
}
</script>
